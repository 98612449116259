const useAPI = async (method, url, body) => {
    return await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          // If request is not successful, display error message
          if (response.status === 401) {
            document.location.href = '/login';
          } else {
            return response.json()
          }
        })
        .catch((err) => {});
};

export default useAPI;