import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import Navigation from './layout/Navigation';

import submitRegistration from '../actions/submitRegistration';
import useAuth from "../hooks/useAuth";

const Register = () => {

    useEffect(() => {
        document.title = 'Register | Building Regs';
    });

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [role, setRole] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [genericError, setGenericError] = useState(null);

    const navigate = useNavigate();
    const { onLogin } = useAuth();

    const submitRegistrationForm = (e) => {
        e.preventDefault();
        setEmailError(null);
        setPasswordError(null);
        setGenericError(null);
        let response = submitRegistration(firstName, lastName, email, password, role, company, phone);

        response.then((res) => {
            if (typeof res.data.user !== 'undefined') {
                onLogin({
                    'id': res.data.user['id'],
                    'firstName': res.data.user['firstName'],
                    'lastName': res.data.user['lastName'],
                    'email': res.data.user['email']
                })
                navigate('/');  
            } else if (typeof res.errors !== 'undefined') {
                for (let key in res.errors) {
                    let error = res.errors[key];
                    switch (error.field) {
                        case 'email':
                            setEmailError(error.message);
                            break;
                        case 'password':
                            setPasswordError(error.message);
                            break;
                        default:
                            setGenericError(error.message);
                            break;
                    }
                }
            }
        });
    }

    return (
        <div>
            <Navigation>
                <Container style={{marginTop: '50px', paddingBottom: '100px'}}>
                    <h2>Register</h2>
                    <Form onSubmit={submitRegistrationForm} style={{marginTop: '20px'}}>
                        <Form.Group className="mb-3" controlId="registerFirstName">
                            <Form.Label>First name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter first name" 
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerLastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter last name" 
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerEmail">
                            <Form.Label>Email address <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter email" 
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                isInvalid={emailError !== null}
                                required
                            />
                            {emailError && (
                                <Form.Control.Feedback type="invalid">
                                    {emailError}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerEmail">
                            <Form.Label>Mobile number</Form.Label>
                            <Form.Control 
                                type="tel" 
                                placeholder="+61"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerPassword">
                            <Form.Label>Password <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter password" 
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                isInvalid={passwordError !== null}
                                required
                            />
                            {passwordError && (
                                <Form.Control.Feedback type="invalid">
                                    {passwordError}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerLastName">
                            <Form.Label>Company</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter last name" 
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Select 
                                onChange={(e) => setRole(e.target.value)}
                                value={role} 
                            >
                                <option value="">Please select...</option>
                                <option value="carpenter">Carpenter</option>
                                <option value="plumber">Plumber</option>
                                <option value="electrician">Electrician</option>
                                <option value="draftsperson">Draftsperson</option>
                                <option value="architect">Architect</option>
                                <option value="engineer">Engineer</option>
                                <option value="painter">Painter</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="tc_confirmation">
                            <Form.Check
                                type="checkbox"
                                id="tc_confirmation"
                                required
                                label={<>I agree that I will comply with the <a href="https://buildingregs.com.au/terms" target="_blank">Terms &amp; Conditions</a> and <a href="https://buildingregs.com.au/privacy" target="_blank">Privacy Policy</a>.</>}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-grid gap-2" controlId="submitButton">
                            <Button variant="primary" type="submit" disabled={(email === '' || password === '')}>
                                Register
                            </Button>
                        </Form.Group>
                        {genericError && (
                            <Alert variant='danger'>
                                {genericError}
                            </Alert>
                        )}
                    </Form>
                    <hr style={{marginTop: '20px', marginBottom: '20px'}} />
                    <p>Already have an account? <a href='/login'>Login here</a></p>
                </Container>
            </Navigation>
        </div>
    )
}

export default Register;