import useAPI from "../hooks/useAPI";

async function submitRegistration(firstName, lastName, email, password, role, company, phone) {
    return useAPI(
      'POST',
      '/auth/signup',
      { firstName, lastName, email, password, role, company, phone }
    );
}

export default submitRegistration;