// import useAuth from "../hooks/useAuth";

const ProtectedRoute = ({ children }) => {
    // const { token } = useAuth();
  
    // if (!token) {
    //   return <Navigate to="/login" replace />;
    // }
  
    return children;
  };

export default ProtectedRoute