import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import getRegulation from '../actions/getRegulation';
import getRegulationParts from '../actions/getRegulationParts';
import submitQuestion from '../actions/submitQuestion';

import Navigation from './layout/Navigation';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import QuestionField from './common/QuestionField';
import LoadingSpinner from './common/LoadingSpinner';
import DocumentOptionCard from './common/DocumentOptionCard';
import QuestionResponse from './common/QuestionResponse';

import './AskQuestion.css';

const AskQuestion = () => {
    const [regulation, setRegulation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [awaitingOptions, setAwaitingOptions] = useState(false);
    const [response, setResponse] = useState(null);
    const { regulationId } = useParams();
    const [documentPartName, setDocumentPartName] = useState(null);
    const [documentOptions, setDocumentOptions] = useState(null);
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        document.title = 'Ask Question | Building Regs';
        const fetchData = async () => {
            let data = await getRegulation(regulationId);
            setRegulation(data.data);
        };
        if (regulation == null) fetchData();
    });

    const submitQuestionForm = (e) => {
        e.preventDefault();
        if (question !== '') {
            setAwaitingOptions(true);
            let apiResponse = getRegulationParts(regulationId, question);
            
            apiResponse.then((res) => {
                if (typeof res.data !== 'undefined') {
                    setDocumentOptions(res.data);
                    setAwaitingOptions(false);
                }
            })
        }
    };

    const getAnswer = (e, documentPartId) => {
        e.preventDefault();
        if (question !== '') {
            setLoading(true);
            setDocumentOptions(null);
            let apiResponse = submitQuestion(regulationId, documentPartId, question);
            
            apiResponse.then((res) => {
                if (typeof res.data !== 'undefined') {
                    setResponse(res.data);
                    const sections = res.data.documentPart.reference.split(', ');
                    setDocumentPartName(sections[sections.length - 1]);
                    setLoading(false);
                }
            })
        }
    }

    const adjustQuestion = (e) => {
        e.preventDefault();
        setLoading(false);
        setResponse(null);
        setDocumentOptions(null);
    }

    return (
        <Navigation loggedIn>
            {regulation && (
                <Container style={{marginTop: '18px', marginBottom: '28px'}}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Ask question</Breadcrumb.Item>
                    </Breadcrumb>
                    
                    <h3 style={{marginTop: '18px', marginBottom: '18px'}}>What would you like to know about the {regulation.name}?</h3>
                    
                    <QuestionField 
                        isEditable={(loading === false && awaitingOptions === false && response === null && documentOptions === null)}
                        submitDisabled={(loading === true || question == null || question.length === 0)}
                        adjustQuestion={adjustQuestion}
                        question={question}
                        setQuestion={setQuestion}
                        submitQuestionForm={submitQuestionForm} />

                    {(response === null && (awaitingOptions === true || documentOptions !== null)) && (
                        <>
                            <h4 className='ResultHeading'>Top 3 results:</h4>

                            {awaitingOptions === true && <LoadingSpinner />}

                            {documentOptions && documentOptions.map((option) => (
                                <DocumentOptionCard 
                                    name={option.reference} 
                                    reference={option.reference} 
                                    optionId={option.id}
                                    onClickAction={getAnswer} />
                            ))}
                        </>
                    )}

                    {(response === null && loading === true) && <LoadingSpinner />}

                    {response !== null && (
                        <>
                            <h4 className='ResultHeading'>Based on {documentPartName}:</h4>
                            <QuestionResponse 
                                answer={response.answer}
                                referenceLink={response.documentPart.link} />
                        </>
                    )}
                </Container>
            )}
        </Navigation>
    );
};

export default AskQuestion;