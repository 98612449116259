import useAPI from "../hooks/useAPI";

async function submitForgotPassword(email) {
    return useAPI(
      'POST',
      '/auth/forgot-password',
      { email }
    );
}

export default submitForgotPassword;