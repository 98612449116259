import React from "react";
import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container';

import './Navigation.css';

const Navigation = (props) => {
    return (
        <div>
            <Navbar bg="light" expand="lg" sticky="top">
                <Container>
                    <Link to="/" style={{textDecoration: 'none'}}><Navbar.Brand>Building Regs</Navbar.Brand></Link>
                    <Nav style={{display: 'inline-block'}}>
                        {props.threadName ? <Nav.Item>
                            <Link to="/" style={{textDecoration: 'none'}}>
                                <Button variant="light">Questions</Button>
                            </Link>
                        </Nav.Item> : null}
                        {props.loggedIn ? <Nav.Item>
                            <Link to="/login">
                                <Button variant="light">Logout</Button>
                            </Link>
                        </Nav.Item> : null}
                    </Nav>
                </Container> 
            </Navbar>
            {props.children}
        </div>
    );
}

export default Navigation;