import React from "react";
import Chevron from "./Chevron";
import './RegulationCard.css';

const RegulationCard = (props) => {
    return (
        <div className="RegulationCard">
            <div className="Body">
                <h2 className="Name">{props.name}</h2>
                {props.description && <span className="Description">{props.description}</span>}
            </div>
            <Chevron />
        </div>
    );
};

export default RegulationCard;