import useAPI from "../hooks/useAPI";

async function submitQuestion(regulationId, documentPartId, question) {
    return useAPI(
      'POST',
      '/api/questions',
      {
        regulationId: regulationId,
        documentPartId: documentPartId,
        question: question
      }
    );
}

export default submitQuestion;