import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner = () => {
    return (
        <Spinner animation="border" role="status" style={{marginTop: '8px'}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );
};

export default LoadingSpinner;