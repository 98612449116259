import useAPI from "../hooks/useAPI";

async function submitResetPassword(userId, token, password) {
    return useAPI(
      'POST',
      '/auth/reset-password',
      { userId, token, password }
    );
}

export default submitResetPassword;