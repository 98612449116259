import React from "react";
import { Routes, Route } from "react-router-dom";

import AuthProvider from './middleware/AuthProvider';
import PrivateRoute from './middleware/PrivateRoute';

import Login from './components/Login';
import Register from './components/Register';
import SelectRegulation from "./components/SelectRegulation";
import AskQuestion from "./components/AskQuestion";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";

const router = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/" element={<PrivateRoute><SelectRegulation /></PrivateRoute> } />
                <Route path="/questions/:regulationId/new" element={<PrivateRoute><AskQuestion /></PrivateRoute> } />
                <Route path="*" element={<NotFound />} status={404} />
            </Routes>
        </AuthProvider>
    )
}

export default router;