import React, { useState, useEffect } from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import Navigation from './layout/Navigation';
import submitForgotPassword from "../actions/submitForgotPassword";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    let submitForgotPasswordForm = (e) => {
        e.preventDefault();
        let response = submitForgotPassword(email);

        response.then((res) => {
            if (typeof res.data.status !== 'undefined') {
                setSubmitted(true);
            } else {
                setEmail('');
            }
        });
    };

    useEffect(() => {
        document.title = 'Forgot Password | Building Regs';
    });

    return (
        <Navigation>
            <Container style={{marginTop: '50px'}}>
                <h2>Forgot your password?</h2>
                <p>Enter your email and we'll send you instructions on how to reset your password.</p>
                <Form onSubmit={submitForgotPasswordForm} style={{marginTop: '20px'}}>
                    {submitted === false && ( 
                        <>
                            <Form.Group className="mb-3" controlId="loginEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Enter email" 
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 d-grid gap-2" controlId="submitButton">
                                <Button variant="primary" type="submit" disabled={email === ''}>
                                    Send instructions
                                </Button>
                            </Form.Group>
                        </>
                    )}
                    {submitted === true && ( 
                        <Form.Group className="mb-3 d-grid gap-2">
                            <Alert variant='success'>
                                Submitted! You will receive an email with instructions if you have a Building Regs account.
                            </Alert>
                        </Form.Group>
                    )}
                    <Form.Text className="text-muted">
                        Go back to <a href="/login">login page</a>
                    </Form.Text>
                </Form>
            </Container>
        </Navigation>
    );
};

export default ForgotPassword;