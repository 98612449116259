import React from "react";
import './RegulationCard.css';

const Chevron = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className='Chevron'>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96888 2.96888C7.03854 2.89903 7.12131 2.84362 7.21243 2.80581C7.30354 2.768 7.40122 2.74854 7.49988 2.74854C7.59853 2.74854 7.69621 2.768 7.78733 2.80581C7.87844 2.84362 7.96121 2.89903 8.03088 2.96888L17.0309 11.9689C17.1007 12.0385 17.1561 12.1213 17.1939 12.2124C17.2318 12.3035 17.2512 12.4012 17.2512 12.4999C17.2512 12.5985 17.2318 12.6962 17.1939 12.7873C17.1561 12.8784 17.1007 12.9612 17.0309 13.0309L8.03088 22.0309C7.89005 22.1717 7.69904 22.2508 7.49988 22.2508C7.30071 22.2508 7.10971 22.1717 6.96888 22.0309C6.82805 21.89 6.74893 21.699 6.74893 21.4999C6.74893 21.3007 6.82805 21.1097 6.96888 20.9689L15.4394 12.4999L6.96888 4.03088C6.89903 3.96121 6.84362 3.87844 6.80581 3.78733C6.768 3.69621 6.74854 3.59853 6.74854 3.49988C6.74854 3.40122 6.768 3.30354 6.80581 3.21243C6.84362 3.12131 6.89903 3.03854 6.96888 2.96888Z" fill="black"/>
        </svg>
    );
};

export default Chevron;