import useAPI from "../hooks/useAPI";

async function submitLogin(email, password) {
    return useAPI(
      'POST',
      '/auth/login',
      { email, password }
    );
}

export default submitLogin;