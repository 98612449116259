import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import Navigation from './layout/Navigation';

import submitLogin from "../actions/submitLogin";
import useAuth from "../hooks/useAuth";

const Login = () => {
    
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { onLogin } = useAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = 'Login | Building Regs';
    });

    let submitLoginForm = (e) => {
        e.preventDefault();
        setError(null);
        let response = submitLogin(email, password);

        response.then((res) => {
            if (typeof res.data.user !== 'undefined') {
                onLogin({
                    'id': res.data.user['id'],
                    'firstName': res.data.user['firstName'],
                    'lastName': res.data.user['lastName'],
                    'email': res.data.user['email']
                })
                navigate('/');  
            } else {
                if (typeof res.errors !== 'undefined') {
                    for (let index in res.errors) {
                        let error = res.errors[index];
                        setError(error.message);
                    }
                }
            }
        });
    }

    return (
        <div>
            <Navigation>
                <Container style={{marginTop: '50px'}}>
                    <h2>Login</h2>
                    <Form onSubmit={submitLoginForm} style={{marginTop: '20px'}}>
                        <Form.Group className="mb-3" controlId="loginEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter email" 
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="loginPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter password" 
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-grid gap-2" controlId="submitButton">
                            <Button variant="primary" type="submit" disabled={(password === '' || email === '')}>
                                Login
                            </Button>
                        </Form.Group>
                        {error && (
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        )}
                        <Form.Text className="text-muted">
                            Forgot password? Reset your password <a href="/forgot-password">here</a>
                        </Form.Text>
                    </Form>
                    <hr style={{marginTop: '20px', marginBottom: '20px'}} />
                    <p>Create a new account <a href='/register'>here</a></p>
                </Container>
            </Navigation>
        </div>
    )
}

export default Login;