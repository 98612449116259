import React from "react";
import './QuestionResponse.css';

const QuestionResponse = (props) => {

    let footerText = <>
        Please refer directly to the regulation. This is our model's interpretation of the code.
    </>
    if (props.referenceLink) {
        footerText = <>
            Please refer directly to the regulation <a href={props.referenceLink} className="Link" target="_blank" rel="noopener noreferrer">here</a>. This is our model's interpretation of the code.
        </>
    }

    return (
        <div className="QuestionResponse">
            <div className="Quote">
                {props.answer}
            </div>
            <div className="Footer">
                {footerText}
            </div>
        </div>
    );
};

export default QuestionResponse