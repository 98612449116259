import useAPI from "../hooks/useAPI";

async function getRegulationParts(regulationId, question) {
    return useAPI(
      'POST',
      '/api/questions/relevant-parts',
      {
        regulationId: regulationId,
        question: question
      }
    );
}

export default getRegulationParts;