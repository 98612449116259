import React from "react";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const QuestionField = (props) => {

    if (props.isEditable) {
        return (
            <div>
                <Form.Control 
                    as="textarea" 
                    rows={3} 
                    onChange={(e) => props.setQuestion(e.target.value)} 
                    value={props.question} 
                    placeholder='Type your question here...' 
                    style={{marginBottom: '18px'}} 
                />
                <Button 
                    disabled={props.submitDisabled}
                    onClick={props.submitQuestionForm} 
                    variant="primary"
                >
                    Ask question
                </Button>
            </div>
        );
    }

    return (
        <Card style={{backgroundColor: '#F8F9FA', marginTop: '18px'}}>
            <Card.Body>
                <Card.Body style={{padding: '0 0 4px 0'}}>{props.question}</Card.Body>
                <Card.Link onClick={props.adjustQuestion}>Adjust question</Card.Link>
            </Card.Body>
        </Card>
    );
};

export default QuestionField;