import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import Navigation from './layout/Navigation';
import submitResetPassword from "../actions/submitResetPassword";

const ResetPassword = () => {

    let [searchParams,] = useSearchParams();
    let [password, setPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [confirmPasswordError, setConfirmPasswordError] = useState('');
    let [submitting, setSubmitting] = useState(false);
    let [submitted, setSubmitted] = useState(false);

    let submitForm = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        let userId = searchParams.get('u');
        let token = searchParams.get('t');

        if (userId === null || token === null) {
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
        }

        let response = submitResetPassword(userId, token, password);
        
        response.then((res) => {
            if (typeof res.data.status !== 'undefined') {
                setSubmitted(true);
            }
        });
    };

    let changeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
        setConfirmPasswordError('');
    }

    useEffect(() => {
        document.title = 'Reset Password | Building Regs';
    });

    return (
        <Navigation>
            <Container style={{marginTop: '50px'}}>
                <h2>Reset your password</h2>
                <Form onSubmit={submitForm} style={{marginTop: '20px'}}>
                    {submitted === false && (
                        <>
                        <Form.Group className="mb-3" controlId="resetPassword1">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter new password" 
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="resetPassword2">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter matching password"
                                onChange={changeConfirmPassword}
                                value={confirmPassword}
                                isInvalid={confirmPasswordError !== ''}
                            />
                            {confirmPasswordError !== '' && (
                                <Form.Control.Feedback type="invalid">
                                    {confirmPasswordError}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 d-grid gap-2" controlId="submitButton">
                            <Button variant="primary" type="submit" disabled={password === '' || confirmPassword === '' || submitting === true}>
                                Reset password
                            </Button>
                        </Form.Group>
                    </>
                    )}
                    {submitted === true && (
                        <Alert variant='success'>
                            Successfully reset your password. Go back to <a href="/login">login page</a> to sign in.
                        </Alert>
                    )}
                    <Form.Text className="text-muted">
                        Go back to <a href="/login">login page</a>
                    </Form.Text>
                </Form>
            </Container>
        </Navigation>
    );
};

export default ResetPassword;