import React from "react";
import Chevron from "./Chevron";
import './DocumentOptionCard.css';

const DocumentOptionCard = (props) => {
    const sections = props.reference.split(', ');
    const name = sections[sections.length - 1];
    sections.pop();
    return (
        <div className="DocumentOptionCard" onClick={(e) => props.onClickAction(e, props.optionId)}>
            <div className="Body">
                <h2 className="Name">{name}</h2>
                {props.reference && <span className="Path">{sections.join(' | ')}</span>}
            </div>
            <Chevron />
        </div>
    );
};

export default DocumentOptionCard;