import React from "react";
import AuthContext from "../context/AuthContext";

import * as amplitude from '@amplitude/analytics-browser';

const AuthProvider = ({ children }) => {
    const [token, setToken] = React.useState(null);
  
    const handleLogin = async (user) => {
      const token = user;
      let userId = String(user.id);
      if (userId.length < 5) {
          let originalUserId = userId;
          for (let i = 0; i < (5 - originalUserId.length); i++) {
              userId = '0' + userId;
          }
      }
      amplitude.setUserId(userId)
      setToken(token);
    };
  
    const handleLogout = () => {
      setToken(null);
    };
  
    const value = {
      token,
      onLogin: handleLogin,
      onLogout: handleLogout,
    };
  
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
  };

export default AuthProvider;