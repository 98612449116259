import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import getRegulations from '../actions/getRegulations';

import Navigation from './layout/Navigation';
import Container from 'react-bootstrap/Container';
import RegulationCard from './common/RegulationCard';

const SelectRegulation = () => {
    const [regulations, setRegulations] = useState(null);

    useEffect(() => {
        document.title = 'Select Regulation | Building Regs';
        const fetchData = async () => {
            let data = await getRegulations();
            setRegulations(data.data);
        };
        if (regulations == null) fetchData();
    });

    return (
        <Navigation loggedIn>
            <Container style={{marginTop: '18px'}}>
                <h3 style={{marginTop: '28px', marginBottom: '22px'}}>What regulation would you like to ask a question about?</h3>
                {regulations && regulations.map((regulation) => (
                    <Link key={regulation.id} style={{textDecoration: 'none', color: 'inherit'}} to={'/questions/' + regulation.id + '/new'}>
                        <RegulationCard 
                            name={regulation.name} 
                            description={regulation.description} />
                    </Link>
                ))}
            </Container>
        </Navigation>
    );
};

export default SelectRegulation;