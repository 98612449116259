import React, { useEffect } from "react";

import Navigation from './layout/Navigation';

const NotFound = () => {
    useEffect(() => {
        document.title = 'Page not found | Building Regs';
    });
    return (
        <Navigation>
            Page not found
        </Navigation>
    )
}

export default NotFound;